<template>
  <div class="body" v-if="mode">
    <mobile-index v-if="mode=='mobile'" />
    <pc-index v-else />
  </div>
</template>
<script>

import mobileIndex from './mobile/index.vue'

import pcIndex from './pc/index.vue'


export default {
  data() {
    return {
      spinning: true,
      url: "",
      tips: "",
      mode:''

    }
  },
  created(){
    window.addEventListener('load', this.setRootFontSize);
    window.addEventListener('resize', this.setRootFontSize);
  },
  mounted() {
    this.setRootFontSize()
  },
  methods: {

    setRootFontSize() {
      // const viewportWidth = window.innerWidth;
    
      let viewportWidth = document.documentElement.clientWidth
      let newFontSize = 10
      if(viewportWidth < 750){
        newFontSize = viewportWidth / 37.5;
        this.mode = "mobile"
      }else{
        newFontSize = viewportWidth / 149.4
        this.mode = 'pc'
      }


      // 设置根元素的字体大小
      console.log("newFontSize",viewportWidth, newFontSize)
      document.documentElement.style.fontSize = `${newFontSize}px`;
    },

  },
  components: {
    pcIndex,
    mobileIndex
  },
  computed: {
    
  }
}
</script>

<style scoped></style>
<template>
  <a-modal :visible="visible" :confirm-loading="confirmLoading" width="50%" :footer="false" v-if="visible"
    class="container" :closable="false">


    <div class="header">
      <img style="width: 100%;" :src="require('@/assets/pc/agreement.png')" alt="" srcset="">
      <div class="title flex flex-column justify-evenly">

        <img style="width: 19.3rem;" :src="require('@/assets/pc/agreement2.png')" alt="">
        <span class="text">请在开始聊天前回答以下三个问题，<br>感谢您的配合！</span>
      </div>
    </div>




    <div class="content">

      <div class="item">
        <div style="position: relative;">
          <img class="item-image" :src="require('@/assets/mobile/question1.png')" alt="">
          <span class="required">*</span>
        </div>

        <div class="flex flex-column justify-between item-question">
          <div class="item-question-title">请选择您的性别：</div>
          <a-radio-group v-model="payload.user" size="large" name="radioGroup">
            <a-radio value="male">
              男
            </a-radio>
            <a-radio value="female">
              女
            </a-radio>
          </a-radio-group>


        </div>
      </div>

      <div class="item">
        <div style="position: relative;">
          <img class="item-image" :src="require('@/assets/mobile/question2.png')" alt="">
          <span class="required">*</span>
        </div>
        <div class="flex flex-column justify-between item-question">
          <div class="item-question-title">请输入您手机尾号后四位（需与问卷一致）：</div>
          <a-input v-model="payload.mobile" size="large" @change="inputChange" placeholder="请输入手机尾号后四位" />
        </div>
      </div>

      <div class="item">
        <div style="position: relative;">
          <img class="item-image" :src="require('@/assets/mobile/question3.png')" alt="">
          <span class="required">*</span>
        </div>
        <div class="flex flex-column justify-between item-question">
          <div class="item-question-title">请选择您的所在地：</div>
          <a-select show-search option-filter-prop="children" :filter-option="filterOption" size="large" placeholder="搜索"
            v-model="payload.province">
            <a-select-option v-for="row in provinceList" :value="row" :key="row">
              {{ row }}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <div>

        <div class="flex justify-center" style="margin-top: 2.7rem;margin-bottom: 2.7rem;">
          <div class="action-button" style="background-color: #c4c4c4;" @click="closeModal">取消</div>
          <div class="action-button" style="background: linear-gradient(179deg, #ffbd96 0%, #ff715a 100%);"
            @click="confirm">确认</div>
        </div>

      </div>

      <div style="font-size: 1.1rem;margin-top: 2.2rem;">
            免责声明：本HPV聊天小助手提供的所有信息仅供参考， 并不构成或替代任何医学或其他专业建议。
          </div>

    </div>


  </a-modal>
</template>
<script>
import { getAction, putAction, postAction } from '@/request/http.js'
var p_list = [
  "上海市",
  "贵池区",
  "东至县",
  "青阳县",
]
export default {
  data() {
    return {

      visible: false,
      spinning: false,
      payload: {
        user: "",
        mobile: "",
        province: undefined
      },
      confirmLoading: false,
      provinceList: []

    }
  },
  mounted() {
    this.provinceList = p_list;
  },
  methods: {
    confirm() {

      if (!this.payload.user) {
        this.$message.warning('请选择性别')
        return false
      }

      if (!this.payload.mobile) {
        this.$message.warning('请输入手机尾号')
        return false
      }

      if (this.payload.mobile.length != 4) {
        this.$message.warning('请输入手机尾号后四位')
        return false
      }

      if (!this.payload.province) {
        this.$message.warning('请选择您的所在地')
        return false
      }

      localStorage.setItem('userType', this.payload.user)
      let values = {
        answer_one: this.payload.user,
        answer_two: this.payload.mobile,
        answer_three: this.payload.province
      }
      values.session_id = localStorage.getItem('topicId')
      values.browser_id = localStorage.getItem('browser_id')
      postAction('answer/add', values).then(res => {
        if (res.code == 0) {
          this.$message.success('操作成功');
          localStorage.setItem('question', 'yes')
          this.$router.push('/chat')
        } else {
          this.$message.warning(res.msg)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    closeModal() {
      this.visible = false
    },
    inputChange(e) {
      console.log('e', e.target.value)
      let value = e.target.value
      this.payload.mobile = (value.match(/(\d{4}|\d+)/) || [''])[0]
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

  },
  computed: {

  }
}
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  z-index: 1;
}

.container>>>.ant-modal {
  border-radius: 2.4rem;
  overflow: hidden;
}

.container>>>.ant-modal-body {
  padding: 0;
}

.header {
  position: relative;
}

.header .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem 5rem;
}

.header .text {
  font-size: 1.6rem;
  font-weight: 500;
  color: #ffffff;
}

.content {
  width: 100%;
  position: relative;
  padding: 2rem 7.5rem;

}


.item {

  width: 100%;
  position: relative;
  background: white;
  margin-bottom: 1.6rem;
  border-radius: 0.5rem;

}

.item>>>.ant-select-selection--single {
  background: #f1f1f17a;
}

.item>>>.ant-input {
  background: #f1f1f17a;
}

.item-image {
  width: 6.3rem;
}

.item-question {
  height: calc(100% - 2.6rem);
  padding: 0.5rem 0;
}

.item-question-title {

  font-size: 1.3rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0.5rem;
}

.action-button {
  width: 19.8rem;
  height: 5.8rem;
  text-align: center;
  line-height: 5.8rem;
  border-radius: 0.5rem;
  color: white;
  font-size: 1.5rem;
  margin: 0 3rem;
}

.content>>>.ant-radio-button-wrapper {
  padding: 0 3rem;
  font-size: 1.6rem;
  font-weight: 400;
}

.required {
  position: absolute;
  margin-left: -0.5rem;
  top: -1rem;
  font-size: 2rem;
  color: red;
}
</style>
<template>
  <div class="container">

    <div class="flex flex-column justify-evenly align-center login">

      <img :src="require('@/assets/pc/title.png')" alt="" style="width:27rem;height: fit-content;margin-bottom: 3.4rem;">

      <div style="width: 100%;margin-top:3rem" class="flex justify-between">

        <div class="bot-type" @click="changeBot('A')">
          <div :class="{ 'shadow': true, 'bot-select-shadow1': botType == 'A' }">
          </div>
          <div :class="{ 'shadow': true, 'bot-select-shadow2': botType == 'A' }"></div>
          <div class="bot-info">
            <div class="img-container flex justify-center">
              <img :src="require('@/assets/mobile/bot1.png')" class="bot-img"
                :style="{ 'width': botType == 'A' ? '50%' : '40%' }">
            </div>

            <div class="bot-type-title" :style="{ 'color': botType == 'A' ? 'white' : 'black' }">疫苗专家</div>
            <div class="bot-type-desc" :style="{ 'color': botType == 'A' ? '#FFFFFF' : '#5E5E5E' }">严谨、精准、透彻</div>
          </div>
          <div class="select-icon" v-if="botType == 'A'">
            <img :src="require('@/assets/pc/select.png')" alt="" srcset="" style="width: 100%;">
          </div>

        </div>

        <div class="bot-type" @click="changeBot('B')">
          <div :class="{ 'shadow': true, 'bot-select-shadow1': botType == 'B' }">
          </div>
          <div :class="{ 'shadow': true, 'bot-select-shadow2': botType == 'B' }"></div>
          <div class="bot-info">
            <div class="img-container flex justify-center">
              <img :src="require('@/assets/mobile/bot2.png')" class="bot-img"
                :style="{ 'width': botType == 'B' ? '50%' : '37%' }">
            </div>

            <div class="bot-type-title" :style="{ 'color': botType == 'B' ? 'white' : 'black' }">护士小姐</div>
            <div class="bot-type-desc" :style="{ 'color': botType == 'B' ? '#FFFFFF' : '#5E5E5E' }">专业、温柔、亲切</div>
          </div>

          <div class="select-icon" v-if="botType == 'B'">
            <img :src="require('@/assets/pc/select.png')" alt="" srcset="" style="width: 100%;">
          </div>
        </div>
      </div>

      <div style="width: 100%;">
        <div class="text" v-if="!topicId">欢迎您使用HPV疫苗小助手，请问如何称呼您？</div>
        <div class="text" v-else>
          <template v-if="otherBotConfirm">欢迎再次访问HPV疫苗小助手，您已重新选择了疫苗小助手开始新的聊天！</template>
          <template v-else>欢迎再次访问HPV疫苗小助手，您希望继续之前的对话，还是重新开始新的聊天？</template>
        </div>

      </div>

      <div style="width: 100%;">
        <input :disabled="topicId ? true : false" type="text" v-model="nickname" class="nickname" placeholder="请输入您的名称">

        <div class="confirm" @click="startChat" v-if="!topicId">开始对话</div>
        <div v-else class="flex justify-between" style="width: 100%;margin-top: 2.3rem">
          <div v-if="!otherBotConfirm" class="flex justify-between" style="width: 100%;height: 5rem;">
            <div class="continue" @click="toChat">继续聊天</div>
            <div class="sure" @click="restart">重新开始</div>
          </div>
          <div class="confirm" @click="toChat" v-else style="height: 100%;margin-top: 0;">开始对话</div>


        </div>


      </div>

      <div style="width: 100%;margin-top:3rem" class="flex justify-between">
        <div style="    width: 31%;
    background: #f7f7f7;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;">
          <img style="height: 4rem;" :src="require('@/assets/mobile/org1.jpg')" alt="" srcset="">
        </div>

        <img style="width: 31%;" :src="require('@/assets/mobile/org2.png')" @click="toPage('https://www.fudan.edu.cn/')"
          alt="" srcset="">


        <div style="    width: 31%;
    background: #f7f7f7;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;">
          <img style="height: 4rem;" :src="require('@/assets/mobile/org3.jpg')" alt="" srcset="">
        </div>




        <!-- <div style="width: 31%;height: 100%;position: relative;">
          
        </div>
        <div style="width: 31%;height: 100%;position: relative;">
          
        </div> -->


      </div>

    </div>




    <a-spin class="spin" :spinning="spinning" v-if="spinning" />

    <agreement-modal ref="agreement" />
  </div>
</template>
<script>


import { getAction, postAction } from '@/request/http.js'
import agreementModal from './agreement.vue'


export default {
  data() {
    return {
      botType: "A",
      nickname: "",
      spinning: false,
      topicId: '',
      otherBotConfirm: false
    }
  },
  mounted() {

    this.nickname = localStorage.getItem('nickname') || ''
    this.botType = localStorage.getItem('botType') || ''
    this.topicId = localStorage.getItem('topicId') || ''

    console.log('topic', this.topicId, this.botType)

  },
  methods: {

    setRootFontSize() {
      const viewportWidth = window.innerWidth;
      // 根据页面宽度动态计算根元素的字体大小
      const newFontSize = viewportWidth / 37.5; // 1rem 等于页面宽度的 1%
      // 设置根元素的字体大小
      document.documentElement.style.fontSize = `${newFontSize}px`;
    },


    changeBot(e) {
      this.botType = e
      localStorage.setItem('botType', e)

      if (this.topicId) {

        let botList = (localStorage.getItem('botList') || '').split(',')

        if (!botList.includes(e)) {
          this.otherBotConfirm = true;
        } else {
          this.otherBotConfirm = false;
        }
      }
    },

    startChat() {

      if (!this.botType) {
        this.$message.warning('请选择类型')
        return false
      }

      if (!this.nickname) {
        this.$message.warning('请输入昵称')
        return false
      }
      let payload = {

        nickname: this.nickname,
        browser_id: localStorage.getItem('browser_id')
      }

      this.spinning = true
      postAction('/record/topic', payload).then(res => {
        this.spinning = false;
        if (res.code == 0) {
          localStorage.setItem('topicId', res.data)
          localStorage.setItem('nickname', payload.nickname)
          localStorage.setItem('botType', this.botType)


          this.$refs.agreement.visible = true;
          // if (!localStorage.getItem('question')){
          //   this.$refs.agreement.visible = true;
          // }else{
          //   this.$router.push('/chat')
          // }


        } else {
          this.$message.warning(res?.msg)
        }

      })

    },

    toChat() {
      if (!localStorage.getItem('question')) {
        this.$refs.agreement.visible = true;
      } else {
        this.$router.push('/chat')
      }
    },

    restart() {
      localStorage.removeItem('topicId')
      localStorage.removeItem('question')
      localStorage.removeItem('nickname')
      localStorage.removeItem('botList')
      localStorage.removeItem('botType')
      location.reload()
    },

    toPage(e) {
      window.open(e)
    },


  },
  created() {

  },
  components: {
    agreementModal
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: white;
  /* display: flex; */
  background-image: url('../../../assets/pc/background.png');
  background-size: 100% 100%;
}

.login {
  padding: 5rem;
  background: white;
  width: 56rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.title {
  width: 100%;
  /* background-image: url('../../../assets/mobile/title.png'); */
  background-size: 100% 100%;
}

.bot-type {

  width: 18rem;
  height: 18rem;
  position: relative;
}

.select-icon {
  position: absolute;
  width: 5rem;
  padding: 0.5rem;
  bottom: 0;
  right: -2rem;
  border-radius: 50%;
  background: white;
}

.bot-type .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2rem;
  background: rgb(242, 242, 242);
}

.bot-select-shadow1 {
  transition: transform 0.5s linear;
  transform: rotate(10deg);
  background-color: #ffe0d4 !important
}

.bot-select-shadow2 {
  transition: transform 0.5s linear;
  transform: rotate(-10deg);
  background: linear-gradient(#ffa17f, #ffc5ab) !important;
}

.bot-info {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
}

.img-container {
  position: relative;
  width: 100%;
  height: 50%;
}

.bot-img {
  position: absolute;
  width: 60%;
  bottom: 0;
}

.bot-type-title {
  font-size: 1.3rem;
  color: black;
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.bot-type-desc {
  font-size: 1rem;
  /* background: rgb(167 167 167 / 22%); */
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
}

.text {
  color: #000000;
  font-size: 1.6rem;
  letter-spacing: 1px;
  margin: 3rem 0 1.5rem;
  font-weight: 500;
  padding: 0 1rem;
}

.nickname {
  width: 100%;
  margin-top: 1rem;
  height: 5rem;
  font-size: 1.6rem;
  padding-left: 2rem;
  border: none;
  background: #eaeaea75;
}

.confirm {
  margin-top: 2.3rem;
  width: 100%;
  text-align: center;
  line-height: 5rem;
  border-radius: 0.3rem;
  color: white;
  background: linear-gradient(#f8cbad, #fe5f31);
  box-shadow: 0 5px 14px 1px #6969695c;
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.sure {
  background: linear-gradient(#f8cbad, #fe5f31);
  padding: 1.2rem 3rem;
  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  word-break: keep-all;
  margin: 0 1rem;
}

.continue {
  background: linear-gradient(#ffd388, #ff8632);
  padding: 1.2rem 3rem;
  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  word-break: keep-all;
  margin: 0 1rem;
}
</style>
<template>
  <div class="container" v-if="visible">


    <img style="width: 100%;position: absolute;top:0" :src="require('@/assets/mobile/agreement-1.png')" alt="" srcset="">



    <div class="content">

      <img :src="require('@/assets/mobile/tip1.png')" alt=""
        style="width: 5.5rem;position: absolute;top: -2rem;left: -1rem;">

      <div>
        <div class="title">知情同意书</div>
        <div class="desc">
          <div>请在开始聊天前回答以下三个问题，</div>
          <div>感谢您的配合！</div>
        </div>
      </div>
      <div style="padding: 1rem;">
        <div class="item">
          <div style="position: relative;">
            <img class="item-image" :src="require('@/assets/mobile/question1.png')" alt="">
            <span class="required">*</span>
          </div>
          <!-- <img class="item-image" :src="require('@/assets/mobile/question1.png')" alt=""> -->
          <div class="flex flex-column justify-between item-question">
            <div class="item-question-title">请选择您的性别：</div>
            <a-radio-group v-model="payload.user" size="large" name="radioGroup">
              <a-radio value="male">
                男
              </a-radio>
              <a-radio value="female">
                女
              </a-radio>
            </a-radio-group>
          </div>
        </div>

        <div class="item">
          <div style="position: relative;">
            <img class="item-image" :src="require('@/assets/mobile/question2.png')" alt="">
            <span class="required">*</span>
          </div>
          <!-- <img class="item-image" :src="require('@/assets/mobile/question2.png')" alt=""> -->
          <div class="flex flex-column justify-between item-question">
            <div class="item-question-title">请输入您手机尾号后四位（需与问卷一致）：</div>
            <a-input v-model="payload.mobile" size="large" @change="inputChange" placeholder="请输入手机尾号后四位" />
          </div>
        </div>

        <div class="item">
          <div style="position: relative;">
            <img class="item-image" :src="require('@/assets/mobile/question3.png')" alt="">
            <span class="required">*</span>
          </div>
          <!-- <img class="item-image" :src="require('@/assets/mobile/question3.png')" alt=""> -->
          <div class="flex flex-column justify-between item-question">
            <div class="item-question-title">请选择您的所在地：</div>
            <a-select show-search option-filter-prop="children" :filter-option="filterOption" size="large"
              v-model="payload.province" placeholder="搜索">
              <a-select-option v-for="row in provinceList" :value="row" :key="row">
                {{ row }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div>

          <div class="flex justify-between">
            <div class="action-button" style="background-color: #c4c4c4;">取消</div>
            <div class="action-button" style="background: linear-gradient(179deg, #ffbd96 0%, #ff715a 100%);"
              @click="confirm">确认</div>
          </div>

          <div style="font-size: 1.1rem;text-align: center;margin-top: 2.2rem;">
            免责声明：本HPV聊天小助手提供的所有信息仅供参考，
            <br>
            并不构成或替代任何医学或其他专业建议。
          </div>
        </div>
      </div>

    </div>

    <div style="width: 100%;padding: 1.5rem;">

      <div class="flex flex-column" style="padding: 2rem;background: white;border-radius: 2rem;">
        <div style="text-align: center;margin-bottom: 2rem;font-size: 1.4rem;font-weight: 400;">合作单位</div>

        <div class="flex justify-between">

          <!-- <img width="30%" :src="require('@/assets/mobile/org3.jpg')" @click="toPage('https://www.d24h.hk/')"  alt="" srcset="">
          <img width="30%" :src="require('@/assets/mobile/org1.jpg')" @click="toPage('https://www.vaccineconfidence.org/')" alt="" srcset="">
          <img width="30%" :src="require('@/assets/mobile/org2.png')" @click="toPage('https://www.fudan.edu.cn/')" alt="" srcset=""> -->


          <div style="    width: 31%;
    background: #f7f7f7;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;">
            <img style="height: 3rem;" :src="require('@/assets/mobile/org1.jpg')" alt="" srcset="">
          </div>

          <img style="width: 31%;" :src="require('@/assets/mobile/org2.png')" @click="toPage('https://www.fudan.edu.cn/')"
            alt="" srcset="">


          <div style="    width: 31%;
    background: #f7f7f7;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;">
            <img style="height: 3rem;" :src="require('@/assets/mobile/org3.jpg')" alt="" srcset="">
          </div>

        </div>

      </div>
    </div>

    <a-spin class="spin" :spinning="spinning" v-if="spinning" />

  </div>
</template>
<script>


import { postAction } from '@/request/http.js'
var p_list = [
  "上海市",
  "贵池区",
  "东至县",
  "青阳县",
]
export default {
  data() {
    return {
      visible: false,
      spinning: false,
      payload: {
        user: "",
        mobile: "",
        province: undefined
      },
      provinceList: []
    }
  },
  mounted() {

    this.provinceList = p_list;

  },
  methods: {


    confirm() {

      if (!this.payload.user) {
        this.$message.warning('请选择性别')
        return false
      }

      if (!this.payload.mobile) {
        this.$message.warning('请输入手机尾号')
        return false
      }

      if (this.payload.mobile.length != 4) {
        this.$message.warning('请输入手机尾号后四位')
        return false
      }

      if (!this.payload.province) {
        this.$message.warning('请选择您的所在地')
        return false
      }

      localStorage.setItem('userType', this.payload.user)
      let values = {
        answer_one: this.payload.user,
        answer_two: this.payload.mobile,
        answer_three: this.payload.province
      }
      values.session_id = localStorage.getItem('topicId')
      values.browser_id = localStorage.getItem('browser_id')
      postAction('answer/add', values).then(res => {
        if (res.code == 0) {
          this.$message.success('操作成功');
          localStorage.setItem('question', 'yes')
          this.$router.push('/chat')
        } else {
          this.$message.warning(res.msg)
        }
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    inputChange(e) {
      console.log('e', e.target.value)
      let value = e.target.value
      this.payload.mobile = (value.match(/(\d{4}|\d+)/) || [''])[0]
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },


    toPage(e) {
      window.open(e)
    },




  },
  created() {

  },
  components: {

  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  position: relative;
  background-color: #a6a3a317;
  padding-top: 11rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: unset !important;
}

.content {
  width: 92vw;
  position: relative;
  left: 4vw;
  border-radius: 2rem;
  background: linear-gradient(#fbece1, #ffffff);
}

.title {
  font-size: 2.5rem;
  font-weight: 400;
  color: #FF4C1F;
  text-align: center;
  background: linear-gradient(52deg, #FF411B 0%, #FF6826 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 2rem
}

.desc {
  margin-top: 1rem;
  text-align: center;
  font-size: 1.2rem;
  font-family: PingFangSCRegular;
  font-weight: 400;
  color: #000000;
  line-height: 2rem;
}

.item {

  width: 100%;
  position: relative;
  background: white;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

}

.item-image {
  width: 6.3rem;
}

.item-question {
  height: calc(100% - 2.6rem);
  padding: 1rem 1.5rem;
}

.item-question-title {

  font-size: 1.3rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0.5rem;
}

.action-button {
  width: 15rem;
  height: 4.5rem;
  text-align: center;
  line-height: 4.5rem;
  border-radius: 3rem;

  color: white;
  font-size: 1.5rem;
}

.required {
  position: absolute;
  margin-left: -0.5rem;
  top: -0.5rem;
  font-size: 2rem;
  color: red;
}
</style>